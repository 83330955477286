<script setup>
const localePath = useLocalePath()
</script>

<template>
  <UPageCard icon="i-fluent-emoji-high-contrast-customs" title="Importul / Devamarea din 2026"
    description="Afla care vor fi taxele de import pentru autoturismele aduse din afara Republicii Moldova incepand cu anul 2026."
    :to="localePath('tools-import-future')">
    <template #icon>
      <div class="w-full flex justify-between">
        <Icon class="w-10 h-10 flex-shrink-0 text-primary" name="i-fluent-emoji-high-contrast-customs" />
        <UBadge color="amber" variant="soft" label="Important!" />
      </div>
    </template>
  </UPageCard>
</template>