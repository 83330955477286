<script setup>
const localePath = useLocalePath()
</script>

<template>
  <ULandingSection title="Acces rapid" :ui="{ wrapper: 'pt-4 mt-0 md:mt-12 sm:py-12', container: 'gap-8 md:gap-8' }">
    <UPageGrid>
      <ToolsImport />
      <UPageCard title='Piese auto' description="Solicitari pentru piese auto noi sau uzate." :to="localePath('parts')"
        icon='i-heroicons-wrench' />
      <!-- <UPageCard title='Arhiva import' description="Arhiva vehiculelor importate din SUA."
        :to="localePath('vehicles-archive')" icon='i-heroicons-archive-box' /> -->
      <UPageCard title='Instrumente' description="Instrumente digitale pentru automobilisti." :to="localePath('tools')"
        icon='i-material-symbols-service-toolbox-outline-rounded' />
    </UPageGrid>
  </ULandingSection>
</template>
