<script setup>
defineRouteRules({
  prerender: true,
});

useSeoMeta({
  title: "Comunitatea de automobilism din Moldova",
  ogTitle: "Comunitatea de automobilism din Moldova",
  description: "Afla informatii despre automobile, solicitari de piese si beneficiaza de instrumente digitale gratuite. Cauta automobile in arhiva sau piese de schimb noi si second hand.",
  ogDescription: "Afla informatii despre automobile, solicitari de piese si beneficiaza de instrumente digitale gratuite. Cauta automobile in arhiva sau piese de schimb noi si second hand.",
})
</script>

<template>
  <div>
    <!-- <ULandingHero title="Comunitatea de automobilism din Moldova" :ui="{ title: 'text-left' }">
      <img src="~/assets/hero/992-c4s-modelimage-sideshot.png" alt="Hero"
        class="absolute z-[-1] top-[250px] md:top-[150px] right-[300px] object-cover w-1/2 md:w-1/3 w-1/2 md:h-1/3">
      <div
        class="absolute -top-[100px] inset-0 landing-gradient z-[-2] [mask-image:radial-gradient(100%_100%_at_top,white,transparent)]" />
      <div
        class="absolute -top-[100px] inset-0 landing-grid z-[-2] [mask-image:radial-gradient(100%_100%_at_top,white,transparent)]" />
    </ULandingHero> -->

    <LandingQuickLinks />
    <PartsInquire landing />
    <!-- <ListingLookup /> -->

    <!-- <ULandingSection title="Automobile adaugate recent in arhiva"
      :ui="{ title: 'w-full text-left', wrapper: 'sm:py-12', container: 'sm:gap-y-8' }">
      <ClientOnly>
        <ListingRecent />
      </ClientOnly>
    </ULandingSection> -->

    <ULandingSection title="Instrumente digitale pentru automobilisti"
      :ui="{ wrapper: 'sm:py-12', container: 'sm:gap-y-8' }">
      <ToolsGrid />
    </ULandingSection>
  </div>
</template>

<style scoped>
.landing-grid {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, rgb(var(--color-gray-300)) 2px, transparent 2px),
    linear-gradient(to bottom, rgb(var(--color-gray-300)) 2px, transparent 2px);
}

.landing-gradient {
  background-size: 100px 100px;
  background-image:
    /* linear-gradient(to right, rgb(var(--color-primary-200)) 200px, transparent 200px), */
    linear-gradient(rgb(var(--color-primary-200)) 100px, transparent 100px);
}

.dark {
  .landing-grid {
    background-image:
      linear-gradient(to right, rgb(var(--color-gray-800)) 2px, transparent 2px),
      linear-gradient(to bottom, rgb(var(--color-gray-800)) 2px, transparent 2px);
  }

  .landing-gradient {
    background-image:
      /* linear-gradient(to right, rgb(var(--color-primary-800)) 100px, transparent 100px), */
      linear-gradient(rgb(var(--color-primary-800)) 100px, transparent 100px);
  }
}
</style>
