<script setup>
const route = useRoute()
</script>

<template>
  <UPageGrid>
    <ToolsAll v-if="route.path !== '/tools'" />
    <ToolsImportFuture />
    <ToolsImport />
    <ToolsLostFound />
    <ToolsBonusMalus />
    <ToolsInsuranceCalculator />
    <ToolsCalculatorCv />
    <ToolsInsuranceCheck />
    <ToolsInsuranceClaimsHistory />
    <ToolsFuel />
    <ToolsEmissions />
  </UPageGrid>
</template>
